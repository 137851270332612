.skill .row {
  display: flex;
  flex-wrap: wrap;
}

.skill-header {
  background-color: #463F3A;
  color: #F4F3EE;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 2.5rem;
}

.skill-header span:last-child {
  background-color: #F4F3EE; 
  height:2rem;
  color: #463F3A;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.skill .images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative; 
}

.skill .image-container {
  flex: 1 1 calc(50% - 10px);
  margin: 5px;
  box-sizing: border-box;
}

.skill .image-container img {
  width: 100%;
  height: 20rem;
  object-fit:cover;
  border: 1px solid #463F3A;
}

.skill ul {
  list-style-type: none;
  padding: 0;
}

.skill li {
  padding: 10px;
}

.skill li {
  border-bottom: 1px dotted #463F3A;
}

.competences-list.two-columns {
  column-count: 2;
  column-gap: 20px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .skill .images {
    display: grid;
    grid-template-columns: 1fr;
  }
  .skill .image-container {
    margin: 0;
    width: 100%;
    height: 20rem;
  }

  .skill .image-container img {
    width: 100%;
    height: 100%;
  }
}

