/* Gestion des small links */

.small-link header {
  width: 100%;
  height:2rem;
  background-color:#463F3A;
  color:#F4F3EE;
  padding-left:0.5rem;
}

.small-link-alt header {
  width: 100%;
  height: 2rem;
  background-color:#463F3A;
  color:#F4F3EE;
  padding-left:0.5rem;
}

.small-link {
  display:block;
  width: 90%;
  height: 15rem;
  text-decoration: none;
  text-transform: uppercase;
}

.small-link-alt {
  display:block;
  width: 90%;
  text-decoration: none;
  text-transform: uppercase;

  font-size: 1.5rem;
  font-weight: bold;
}

.span-image-smallLink {
  width:100%;
  height: 85%;
  background-size: 100%; 
  background-repeat: no-repeat; 
  background-position: center;
  position: relative;
  display: block;
  transition: 0.4s ease-in-out background-size;
}

.span-image-smallLink::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  z-index: 0;
}

.small-link:hover span {
  background-size: 103%;
}

.badge-info {
  color:#463F3A;
  background-color: #F4F3EE; 
  height:1.5rem;
}

@media (max-width: 768px) {
  .small-link span {
    background-size:cover;
  }

  .small-link {
    width: 100%;
    height: 15rem;
  }

  .small-link-alt {
    width: 100%;
  }

  .small-link-alt header {
    height: 4rem;
    background-color:#463F3A;
    color:#F4F3EE;
    padding-left:0.5rem;
  }
} 
