.SkillTitle> h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #877549;
  line-height: 1.5rem;
  margin: 0;
  padding-left: 0.5rem;
}

.SkillTitle {
  position: relative;
}

.sticky-title {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  padding: 10px 0;
  z-index: 1000; 
  background-color: #F4F3EE;
  border-bottom: 1px solid #463F3A;
}
