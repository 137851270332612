
@keyframes textReveal {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-text h1, .header-text h2 {
  display: inline-block;
  opacity: 0;
  animation: textReveal 0.8s ease-out forwards;
}

.header-text h1 {
  animation-delay: 0.3s;
}

.header-text h2 {
  animation-delay: 0.6s;
}

