@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  font-family: 'IBM Plex Sans', 'Roboto', sans-serif;
}

* ::selection {
  color:#463F3A;
  background:#F4F3EE;
}

body {
  background-color: #F4F3EE !important;
  overflow-x:hidden;
  padding-bottom:5rem;
}

#portfolio-footer {
  background-color:#463F3A;
  color: antiquewhite;
  position:fixed;
  bottom:0%;
  z-index:5001;
}

@media (max-width: 768px) {
  #portfolio-footer {
    position:static
  }

  body {
    padding-bottom:0;
  }
}

.nav-buttons a {
  display:flex;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 0.125rem;
  position:fixed;
  right:5%;
  top:5%;
  z-index:5
}

.nav-buttons a img {
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  transition: 0.2s ease-in-out transform;
}

#btn-backtrack:hover img {
  transform: scale(0.97);
}

.header-part {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 20rem;
} 

.header-text {
  color: #F4F3EE;
}

.header-text h1 {
  font-size:3rem;
  text-transform: uppercase;
  font-weight: 600;
}

.header-text h2 {
  font-size:2.5rem;
}

#portfolio-footer p, #portfolio-footer a, #portfolio-footer span {
  padding: 1rem;
  margin: 0px;
}


