/* Gestion des big links */

.big-link {
  position: relative;
  display: block;
  width: 90%;
  height: 20rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #F4F3EE;
}

.big-link-alt {
  position: relative;
  display: block;
  width: 90%;
  text-decoration: none;
  text-transform: uppercase;
  color: #F4F3EE;
}

.span-image-bigLink {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  transition: 0.4s ease-in-out background-size;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.span-image-bigLink::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  z-index: 2;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background: rgba(70, 63, 58, 0.8); 
  z-index: 3;
  transition: 0.4s ease-in-out;
}

.text-overlay h1, .text-overlay p {
  margin: 0;
}

.description {
  font-size: 1rem;
  color: #F4F3EE;
  text-transform:none;
}

@media (max-width: 768px) {
  .big-link {
    width: 100%;
    height: 10rem;
  }

  .text-overlay {
    padding: 0.5rem;
  }

  .description {
    font-size: 0.7rem;
  }
}

.big-link:hover .span-image-bigLink {
  background-size: 102%;
}

.big-link:hover .text-overlay {
  background: rgba(70, 63, 58, 0.9); 
}
