.about-section {
  background-color: #f8f9fa;
  padding: 2rem;
}

.about-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}
